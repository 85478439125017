/* eslint-disable */
import axiosInstance from '@/services/AxiosInstance'
import UserProfileValidations from '@/services/UserProfileValidations'
import {
  GET_MEMBERS_ACTION,
  SET_MEMBERS_STATE,
  APP_MEMBERS_ACTION,
  DELETE_MEMBER_ACTION,
  LOGOUT_ACTION,
  SET_MEMBERS_ERROR
} from '@/store/storeconstants'

export default {
  async [GET_MEMBERS_ACTION](context, payload) {
    const { commit, dispatch, state } = context

    commit(SET_MEMBERS_STATE, {
      ...state,
      isLoading: true
    })

    try {
      const response = await axiosInstance.get('/app/members')
      if (response.status === 200) {
        const { data } = response
        const members = data.data

        localStorage.setItem('dataMembers', JSON.stringify(members))
        commit(SET_MEMBERS_STATE, {
          ...state,
          members,
          isLoading: false
        })
      }
    } catch (error) {
      const { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }
      commit(SET_MEMBERS_STATE, {
        ...state,
        error: response.data,
        isLoading: false
      })
    }
  },

  async [APP_MEMBERS_ACTION](context, payload) {
    let members = localStorage.getItem('dataMembers')
    if (members) {
      context.commit(SET_MEMBERS_STATE, {
        ...context.state,
        members: JSON.parse(members)
      })
    }
  },

  async [DELETE_MEMBER_ACTION](context, id) {
    const { commit, dispatch, state } = context
    commit(SET_MEMBERS_STATE, {
      ...state,
      isLoading: true
    })

    try {
      const response = await axiosInstance.delete(`/app/users/${id}`)
      if (response.status === 200) {
        const { data } = response

        let dataMember = localStorage.getItem('dataMembers')
        let parseDataMember = JSON.parse(dataMember)
        let newDataMember = parseDataMember.filter(
          (item) => parseInt(item.id) !== parseInt(id)
        )

        localStorage.setItem('dataMembers', JSON.stringify(newDataMember))
        commit(SET_MEMBERS_STATE, {
          ...state,
          members: newDataMember,
          isLoading: false
        })

        return data.message
      }
    } catch (error) {
      const { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }
      commit(SET_MEMBERS_STATE, {
        ...state,
        error: response.data,
        isLoading: false
      })

      return response.data
    }
  }
}
