/* eslint-disable */
import router from '@/router'
import axiosInstance from '@/services/AxiosInstance'
import {
  SET_PAPIKOSTICK_STATE,
  PAPIKOSTICK_QUESTION_ACTION,
  SUBMIT_PAPIKOSTICK_ACTION,
  PAPIKOSTICK_RESULTS_USER_ACTION,
  LOGOUT_ACTION
} from '@/store/storeconstants'

export default {
  async [SUBMIT_PAPIKOSTICK_ACTION](context, payload) {
    const { dispatch, commit, rootState, state } = context

    let postData = {
      s: payload,
      user_id: rootState.auth.id
    }

    commit(SET_PAPIKOSTICK_STATE, {
      ...state,
      error: null,
      isLoading: true
    })

    try {
      let response = await axiosInstance.post(`/app/results`, postData)
      if (response.status === 201) {
        let { data } = response
        commit(SET_PAPIKOSTICK_STATE, {
          ...state,
          results: data,
          isLoading: false
        })

        router.replace('/me/papikostick')
      }
    } catch (error) {
      let { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }
      commit(SET_PAPIKOSTICK_STATE, {
        ...state,
        error: response.data.message,
        isLoading: false
      })
    }
  },

  async [PAPIKOSTICK_RESULTS_USER_ACTION](context, payload) {
    const { commit, dispatch, state, rootState } = context

    commit(SET_PAPIKOSTICK_STATE, {
      ...state,
      isLoading: true
    })

    const userId = payload ? payload : rootState.auth.id

    try {
      let response = await axiosInstance.get(
        `app/aspects/result/user/${userId}`
      )
      let { data } = response.data
      let results = null
      if (data.length > 0) {
        results = data
      }

      commit(SET_PAPIKOSTICK_STATE, {
        ...state,
        results: results,
        isLoading: false
      })
    } catch (error) {
      let { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }

      commit(SET_PAPIKOSTICK_STATE, {
        ...state,
        error: response.data,
        isLoading: false
      })
    }
  },

  async [PAPIKOSTICK_QUESTION_ACTION](context, payload) {
    const { commit, dispatch, state } = context

    commit(SET_PAPIKOSTICK_STATE, {
      ...state,
      isLoading: true
    })

    try {
      let response = await axiosInstance.get(`app/statements`)
      let { data } = response
      commit(SET_PAPIKOSTICK_STATE, {
        ...state,
        questions: data,
        isLoading: false
      })
    } catch (error) {
      let { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }
      commit(SET_PAPIKOSTICK_STATE, {
        ...state,
        error: response.data,
        isLoading: false
      })
    }
  }
}
