<template>
  <footer class="footer py-4 bg-primary text-white">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 text-lg-start">Copyright &copy; Inspirasee</div>
        <!-- <div class="col-lg-4 my-3 my-lg-0">
          <a
            class="btn btn-dark btn-social mx-2"
            href="#!"
            aria-label="Instagram"
          >
            <fa :icon="['fab', 'fa-instagram']" />
          </a>
          <a
            class="btn btn-dark btn-social mx-2"
            href="#!"
            aria-label="Facebook"
            ><fa :icon="['fab', 'fa-facebook']"
          /></a>
          <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter"
            ><fa :icon="['fab', 'fa-x-twitter']"
          /></a>
        </div> -->
        <div class="col-lg-6 text-lg-end">Sejak 2024 - {{ currentYear }}</div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
