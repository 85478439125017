/* eslint-disable */
import router from '@/router'
import axiosInstance from '@/services/AxiosInstance'
import {
  DISC_PERSONALITY_ACTION,
  SUBMIT_DISC_SCORE_ACTION,
  SET_DISC_PERSONALITY,
  DISC_SCORE_USER_ACTION,
  LOGOUT_ACTION
} from '@/store/storeconstants'

export default {
  async [SUBMIT_DISC_SCORE_ACTION](context, payload) {
    const { commit, rootState, state } = context
    let postData = {
      score: payload,
      user_id: rootState.auth.id
    }

    commit(SET_DISC_PERSONALITY, {
      ...state,
      isLoading: true,
      error: null
    })

    try {
      let response = await axiosInstance.post(`/app/disc-scores`, postData)
      if (response.status === 201) {
        let { data } = response.data

        commit(SET_DISC_PERSONALITY, {
          ...state,
          scores: data,
          isLoading: false
        })
        router.replace('/me/disc')
      }
    } catch (error) {
      let { response } = error
      commit(SET_DISC_PERSONALITY, {
        ...state,
        error: response.data.message,
        isLoading: false
      })
    }
  },

  async [DISC_PERSONALITY_ACTION](context, payload) {
    const { commit, dispatch, state } = context
    commit(SET_DISC_PERSONALITY, {
      ...state,
      error: null,
      isLoading: true
    })

    try {
      let response = await axiosInstance.get(`/app/disc-personalities`)
      let { data } = response
      let resultData = {
        ...state,
        personalities: data,
        isLoading: false
      }
      commit(SET_DISC_PERSONALITY, resultData)
    } catch (error) {
      let { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }
      commit(SET_DISC_PERSONALITY, {
        ...state,
        error: response.data,
        isLoading: false
      })
    }
  },

  async [DISC_SCORE_USER_ACTION](context, payload) {
    const { commit, dispatch, rootState, state } = context
    commit(SET_DISC_PERSONALITY, {
      ...state,
      error: null,
      isLoading: true
    })

    let user_id = payload
    try {
      let response = await axiosInstance.get(`/app/disc-scores/${user_id}`)
      let { data } = response

      commit(SET_DISC_PERSONALITY, {
        ...state,
        scores: data,
        isLoading: false
      })
    } catch (error) {
      let { response } = error
      if (response.status === 401) {
        dispatch(`auth/${LOGOUT_ACTION}`, null, {
          root: true
        })
      }
      commit(SET_DISC_PERSONALITY, {
        ...state,
        error: response.data.message,
        isLoading: false
      })
    }
  }  
}
