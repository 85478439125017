<template>
  <router-view />
</template>

<script>
import { AUTH_LOGIN_ACTION, APP_MEMBERS_ACTION } from './store/storeconstants'
export default {
  name: 'App',
  created() {
    this.$store.dispatch(`auth/${AUTH_LOGIN_ACTION}`)
    this.$store.dispatch(`member/${APP_MEMBERS_ACTION}`)
  }
}
</script>

<style lang="scss">
#app {
  a {
    text-decoration: none;
  }
}
</style>
