<template>
  <nav
    :class="[
      'navbar navbar-expand-lg navbar-dark fixed-top',
      { 'navbar-shrink': isNavbarShrink }
    ]"
    id="mainNav"
  >
    <div class="container">
      <a class="navbar-brand" href="#" @click.prevent="scrollToTop">
        <!-- QNC Psikotes -->
        <img :src="require('../assets/img/Inspirasee.svg')" alt="inspirasee" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        Menu
        <i class="fas fa-bars ms-1"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#tests"
              @click.prevent="scrollToSection('tests', $event)"
              >Psikotes</a
            >
          </li>
          <li class="nav-item" v-if="level === 'admin'">
            <a
              class="nav-link"
              href="#members"
              @click.prevent="scrollToSection('members', $event)"
              >Peserta</a
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/me">Tentang Saya</router-link>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="btn btn-primary btn-md text-capitalize"
              @click.prevent="onLogout()"
              >Logout</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import { mapActions } from 'vuex'
import { LOGOUT_ACTION } from '@/store/storeconstants'
export default {
  name: 'AppNavbar',
  props: {
    level: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isNavbarShrink: false
    }
  },
  mounted() {
    this.navbarShrink()

    // Shrink the navbar when page is scrolled
    window.addEventListener('scroll', this.navbarShrink)

    // Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.getElementById('mainNav')
    if (mainNav) {
      new bootstrap.ScrollSpy(document.body, {
        target: '#mainNav',
        rootMargin: '0px 0px -40%'
      })
    }

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.querySelector('.navbar-toggler')
    const responsiveNavItems = document.querySelectorAll(
      '#navbarResponsive .nav-link'
    )
    responsiveNavItems.forEach((responsiveNavItem) => {
      responsiveNavItem.addEventListener('click', () => {
        if (window.getComputedStyle(navbarToggler).display !== 'none') {
          navbarToggler.click()
        }
      })
    })
  },
  methods: {
    ...mapActions('auth', {
      logout: LOGOUT_ACTION
    }),

    onLogout() {
      this.logout()
    },

    navbarShrink() {
      const navbarCollapsible = document.getElementById('mainNav')
      if (!navbarCollapsible) {
        return
      }
      if (window.scrollY === 0) {
        this.isNavbarShrink = false
      } else {
        this.isNavbarShrink = true
      }
    },

    scrollToSection(sectionId, event) {
      event.preventDefault()
      const section = document.getElementById(sectionId)
      section.scrollIntoView({ behavior: 'smooth' })
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.navbarShrink)
  }
}
</script>
