export const SIGNUP_ACTION = '[actions] signup user'
export const LOGIN_ACTION = '[actions] login user'
export const LOGOUT_ACTION = '[actions] logout user'
export const AUTH_ACTION = '[actions] DO User Auth'
export const AUTH_LOGIN_ACTION = '[actions] Auth Auto Login'
export const SET_USER_REGISTER = '[mutations] set user register'
export const SET_USER_TOKEN_MUTATION = '[mutations] set user token'
export const GET_USER_TOKEN_GETTER = '[getters] auth user token'
export const IS_USER_AUTHENTICATE_GETTER = '[getters] is user authenticated'
export const CREATE_USER_PROFILE_ACTION = '[actions] create a user profile'
export const SET_USER_ERROR_MUTATION = '[mutations] set user error'
export const SET_USER_ERRORS_MUTATION = '[mutations] set user errors'
export const SET_USER_LOADING_MUTATION = '[mutations] set user loading'

export const PAPIKOSTICK_QUESTION_ACTION =
  '[actions] fetch papikostick question'
export const SUBMIT_PAPIKOSTICK_ACTION = '[actions] submit papikostick'
export const PAPIKOSTICK_RESULTS_USER_ACTION =
  '[actions] result papikostick this user'
export const SET_PAPIKOSTICK_STATE = '[mutations] set papikostick state'
export const SET_PAPIKOSTICK_ERROR = '[mutations] set papikostick error'
export const GET_PAPIKOSTICK_QUESTIONS = '[getters] get papikostick questions'
export const GET_PAPIKOSTICK_RESULTS = '[getters] get papikostick results'
export const GET_PAPIKOSTICK_JOB_POSITION_MIN =
  '[getters] get papikostick job position min'
export const GET_PAPIKOSTICK_JOB_POSITION_MAX =
  '[getters] get papikostick job position max'
export const DISC_PERSONALITY_ACTION = '[actions] fetch disc personality'
export const SUBMIT_DISC_SCORE_ACTION = '[actions] submit disc score'
export const DISC_SCORE_USER_ACTION = '[actions] disc score user'
export const SET_DISC_PERSONALITY = '[mutations] set disc state'
export const SET_DISC_ERROR = '[mutations] set disc error'
export const GET_DISC_PERSONALITY = '[getters] get disc state'
export const DISC_PATTERN_ACTION = '[actions] pattern id'
export const SET_DISC_PATTERN = '[mutations] set pattern'
export const DISC_RESULT_USER_ACTION = '[actions] disc result user'
export const SET_DISC_RESULT_USER = '[mutations] set disc result user'

export const SET_MEMBERS_STATE = '[mutations] set members'
export const APP_MEMBERS_ACTION = '[actions] session members'
export const SET_MEMBERS_ERROR = '[mutations] set member error'
export const GET_MEMBERS = '[getters] get members'
export const GET_MEMBER_ERROR = '[getters] get member error'
export const SUBMIT_MEMBER = '[actions] submit a member'
export const DELETE_MEMBER_ACTION = '[actions] delete a member'
export const GET_MEMBERS_ACTION = '[actions] get members'
