<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
    id="sideNav"
  >
    <a class="navbar-brand js-scroll-trigger" href="/">
      <span class="d-block d-lg-none"
        >Inspirasee | {{ username ? username : 'Tanpa Nama' }}</span
      >
      <span class="d-none d-lg-block"
        ><img
          class="img-fluid mx-auto mb-2"
          src="../assets/img/Inspirasee.svg"
          alt="..."
      /></span>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" :to="`/${pathname}`"
            >Tentang {{ pathname === 'me' ? 'Saya' : username }}</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="`/${pathname}/papikostick`"
            >PAPI Kostick</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="`/${pathname}/disc`"
            >DISC</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    username: {
      type: String
    }
  },
  data() {
    return {
      pathname: 'me'
    }
  },
  mounted() {
    let { name, params } = this.$route
    let { id } = params
    if (name.includes('me')) this.pathname = 'me'
    else this.pathname = `member/${id}`
  }
}
</script>
