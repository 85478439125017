<template>
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Psikotes</h2>
      <h3 class="section-subheading text-muted">
        Silahkan klik salah satu icon tes di bawah ini!
      </h3>
    </div>
    <div class="row text-center d-flex justify-content-between">
      <div class="col-md-6">
        <router-link to="/me/papikostick" class="fa-stack fa-4x">
          <fa :icon="['fas', 'fa-circle']" class="fa-stack-2x text-primary" />
          <fa :icon="['fas', 'fa-list-check']" class="fa-stack-1x fa-inverse" />
        </router-link>
        <h4 class="my-3">PAPI Kostick</h4>
        <p class="text-muted">
          Tes PAPI Kostick merupakan akronim dari Personality and Preference
          Inventory Test. Tes psikologi dengan metode PAPI Kostick ini merupakan
          suatu alat tes untuk melakukan penilaian kepribadian terkemuka.
        </p>
      </div>
      <div class="col-md-6">
        <router-link to="/me/disc" class="fa-stack fa-4x">
          <fa :icon="['fas', 'fa-circle']" class="fa-stack-2x text-primary" />
          <fa :icon="['fas', 'fa-list-check']" class="fa-stack-1x fa-inverse" />
        </router-link>
        <h4 class="my-3">DISC</h4>
        <p class="text-muted">
          DiSC test adalah jenis psikotes yang digunakan untuk menilai kemampuan
          seseorang dalam melakukan kerja tim, komunikasi, dan produktivitas di
          tempat kerja.
        </p>
      </div>
    </div>
  </div>
</template>
