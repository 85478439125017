<template>
  <section class="resume-section">
    <div class="resume-section-content">
      <h2 class="mb-5">Disc Personality Test</h2>
      <div class="subheading mb-3">Petunjuk</div>
      <p>
        Setiap nomor di bawah ini memuat 4 (empat) kalimat. Tugas anda adalah :
        <br />
      </p>
      <ol>
        <li>
          Beri tanda/cek pada kolom di bawah huruf [P] di samping kalimat yang
          PALING menggambarkan diri anda
        </li>
        <li>
          Beri tanda/cek pada kolom di bawah huruf [K] di samping kalimat yang
          PALING TIDAK menggambarkan diri anda
        </li>
      </ol>
      <div
        class="alert alert-warning alert-dismissible fade show"
        role="alert"
        v-if="error"
      >
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          @click.prevent="closeAlert"
        ></button>
        <h4 class="alert-heading">Gagal!</h4>
        <p>{{ error }}</p>
      </div>

      <table v-if="isLoading" class="table">
        <tr>
          <td colspan="6" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </table>

      <div v-else class="row px-3">
        <template
          v-for="(group, groupIndex) in personalitiesGrouped"
          :key="groupIndex"
        >
          <div :class="[screenWidth <= 992 ? 'col-12' : 'col-6', 'px-0']">
            <table class="table table-primary table-condensed">
              <thead>
                <tr>
                  <th width="8%" class="text-center">No</th>
                  <th>Gambaran Diri</th>
                  <th width="7%" class="text-center">P</th>
                  <th width="7%" class="text-center">K</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(personality, index) in group" :key="index">
                  <tr>
                    <td
                      rowspan="5"
                      width="5%"
                      class="table-transparent text-center align-middle"
                    >
                      {{ personality[0].no }}.
                    </td>
                  </tr>
                  <tr v-for="(person, idx) in personality" :key="idx">
                    <td :class="idx < 3 ? 'border-bottom-0' : ''">
                      {{ person.term }}
                    </td>
                    <td
                      :class="[idx < 3 ? 'border-bottom-0' : '', 'text-center']"
                    >
                      <!-- <div class="form-check bg-primary" style> -->
                      <input
                        class="form-check-input"
                        type="radio"
                        :id="`m_${person.no}_${idx}`"
                        :name="'m[' + person.no + ']'"
                        :value="person.most"
                        v-model="personalityValues[`m[${person.no}]`]"
                        @change="validateSelection(person, idx, 'm')"
                        required
                      />
                      <!-- </div> -->
                    </td>
                    <td
                      :class="[idx < 3 ? 'border-bottom-0' : '', 'text-center']"
                    >
                      <!-- <div class="form-check"> -->
                      <input
                        class="form-check-input"
                        type="radio"
                        :id="`l_${person.no}_${idx}`"
                        :name="'l[' + person.no + ']'"
                        :value="person.least"
                        v-model="personalityValues[`l[${person.no}]`]"
                        @change="validateSelection(person, idx, 'l')"
                        required
                      />
                      <!-- </div> -->
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>
        <div class="mt-3 px-0">
          <button
            class="btn btn-success float-end"
            :disabled="isLoading"
            @click.prevent="onSubmit()"
          >
            Submit &nbsp;
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  DISC_PERSONALITY_ACTION,
  SUBMIT_DISC_SCORE_ACTION,
  SET_DISC_ERROR
} from '@/store/storeconstants'

export default {
  data() {
    return {
      personalitiesGroupByNo: {},
      personalitiesGrouped: [[], []],
      personalityValues: {},
      selectionChecks: [],
      screenWidth: window.innerWidth
    }
  },

  mounted() {
    this.fetchDataPersonalities()
  },

  computed: {
    ...mapState('disc', {
      personalities: (state) => state.personalities,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    })
  },

  methods: {
    ...mapActions('disc', {
      fetchData: DISC_PERSONALITY_ACTION,
      submit: SUBMIT_DISC_SCORE_ACTION
    }),

    async fetchDataPersonalities() {
      await this.fetchData()
      this.groupPersonalitiesByNo()
      this.groupPersonalities()
    },

    async onSubmit() {
      if (this.indexesNotExist(this.personalityValues).length > 0) {
        const nomor = this.indexesNotExist(this.personalityValues).join(',')
        const errorMessage = `Nomor ${nomor} belum dipilih, coba periksa kembali!`
        this.$store.commit(`disc/${SET_DISC_ERROR}`, errorMessage)
        return false
      }

      await this.submit(this.calculateDISC())
    },

    groupPersonalitiesByNo() {
      this.personalities.forEach((item) => {
        if (!(item.no in this.personalitiesGroupByNo)) {
          this.personalitiesGroupByNo[item.no] = []
        }
        this.personalitiesGroupByNo[item.no].push(item)
      })
    },

    groupPersonalities() {
      Object.entries(this.personalitiesGroupByNo).forEach(
        ([key, personalityArray]) => {
          if (this.screenWidth <= 992) {
            this.personalitiesGrouped[0].push(personalityArray)
          } else {
            if (key <= 12) {
              this.personalitiesGrouped[0].push(personalityArray)
            } else if (key >= 13 && key <= 24) {
              this.personalitiesGrouped[1].push(personalityArray)
            }
          }
        }
      )

      this.personalitiesGrouped = this.personalitiesGrouped.filter(
        (subArr) => subArr.length > 0
      )
    },

    validateSelection(person, idx, type) {
      const key = `${type}_${person.no}_${idx}`
      const options = key.split('_').filter(Boolean)

      this.$store.commit(`disc/${SET_DISC_ERROR}`, null)
      this.selectionChecks.push(options)
      this.checkIndexSelectionType(this.selectionChecks, type, person)
    },

    checkIndexSelectionType(arr, type, person) {
      for (let i = arr.length - 1; i >= 0; i--) {
        for (let j = i - 1; j >= 0; j--) {
          // Memeriksa apakah ada indeks ke-0 dan ke-1 yang sama
          if (
            arr[i] &&
            arr[j] &&
            arr[i][0] === arr[j][0] &&
            arr[i][1] === arr[j][1]
          ) {
            arr.splice(j, 1) // Menghapus array sebelumnya
          } else if (
            arr[i] &&
            arr[j] &&
            arr[i][0] !== arr[j][0] &&
            arr[i][1] === arr[j][1] &&
            arr[i][2] === arr[j][2]
          ) {
            arr.splice(i, 1) // Menghapus array saat ini
            this.personalityValues[`${type}[${person.no}]`] = false
            const errorMessage = `Anda Tidak bisa memilih P dan K pada Gambaran diri yang sama!`
            this.$store.commit(`disc/${SET_DISC_ERROR}`, errorMessage)
            break
          }
        }
      }
    },

    indexesNotExist(data) {
      const indeksM = []
      const indeksL = []

      // Membuat Set untuk memastikan indeks unik
      const setM = new Set()
      const setL = new Set()

      // Mengambil indeks dari kunci m dan l yang sudah ada
      for (const kunci in data) {
        if (kunci.startsWith('m[')) {
          const indeks = parseInt(kunci.substring(2, kunci.length - 1))
          setM.add(indeks)
        } else if (kunci.startsWith('l[')) {
          const indeks = parseInt(kunci.substring(2, kunci.length - 1))
          setL.add(indeks)
        }
      }

      // Memeriksa indeks m yang belum ada
      for (let i = 1; i <= 24; i++) {
        if (!setM.has(i)) {
          indeksM.push(i)
        }
      }

      // Memeriksa indeks l yang belum ada
      for (let i = 1; i <= 24; i++) {
        if (!setL.has(i)) {
          indeksL.push(i)
        }
      }

      return [...new Set([...indeksM, ...indeksL])]
    },

    calculateDISC() {
      // Inisialisasi skor awal untuk setiap dimensi
      let scores = {
        most: { D: 0, I: 0, S: 0, C: 0, N: 0 },
        least: { D: 0, I: 0, S: 0, C: 0, N: 0 },
        change: { D: 0, I: 0, S: 0, C: 0, N: 0 }
      }

      for (let key in this.personalityValues) {
        let keys = key.split(/[[\]]+/).filter(Boolean)
        let type = keys[0]

        let value = this.personalityValues[key]

        switch (type) {
          case 'm':
            scores['most'][value]++
            break
          case 'l':
            scores['least'][value]++
            break
        }
      }

      scores.change = {
        D: scores.most.D - scores.least.D,
        I: scores.most.I - scores.least.I,
        S: scores.most.S - scores.least.S,
        C: scores.most.C - scores.least.C,
        N: scores.most.N - scores.least.N
      }

      return scores
    }
  }
}
</script>

<style lang="scss" scoped>
table.table-primary {
  margin-bottom: 0;
  td {
    padding: 10px 0;
    background-color: transparent;
  }
}

input[type='radio'] {
  font-size: 26px;
}
</style>
