<template>
  <section class="resume-section">
    <div class="resume-section-content">
      <h2 class="mb-5">PAPI Kostick</h2>
      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="flex-grow-1">
          <div class="subheading mb-3">Informasi</div>
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div v-if="results">
              <div v-if="pathname === 'me'">
                <p>
                  Terima kasih Anda sudah melakukan tes ini <br />
                  Hasilnya hanya bisa dilihat oleh admin kami
                </p>
              </div>
              <div v-else>
                <papi-kostick-result :results="results" :member="member" />
              </div>
            </div>
            <div v-else>
              <div v-if="pathname === 'me'">
                <p>Anda belum pernah melakukan test!</p>
                <a
                  class="btn btn-primary text-uppercase"
                  :href="`/${pathname}/papikostick/instruction`"
                >
                  Petunjuk Test
                </a>
              </div>
              <p v-else>
                <span class="text-primary text-capitalize">{{
                  member && member.username
                }}</span>
                belum pernah melakukan test!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { PAPIKOSTICK_RESULTS_USER_ACTION } from '@/store/storeconstants'
import PapiKostickResult from '@/components/PapiKostickResult.vue'

export default {
  name: 'ResultView',
  components: {
    PapiKostickResult
  },
  data() {
    return {
      pathname: 'me',
      member: null,
      memberId: null
    }
  },
  computed: {
    ...mapState('papikostick', {
      results: (state) => state.results,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    }),

    ...mapState('member', {
      members: (state) => state.members
    })
  },
  mounted() {
    this.getMember()
    this.onGetResult()
  },
  methods: {
    ...mapActions('papikostick', {
      getResults: PAPIKOSTICK_RESULTS_USER_ACTION
    }),

    async onGetResult() {
      await this.getResults(this.memberId)
    },

    getMember() {
      let { name } = this.$route
      if (name.includes('me')) this.pathname = 'me'
      else this.pathname = 'member'

      const { params } = this.$route

      if (this.pathname !== 'me' && params.id !== undefined) {
        this.memberId = params.id
        this.member = this.members.find(
          (item) => parseInt(item.id) === parseInt(this.memberId)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table td {
  border: none;
}
</style>
