<template>
  <header class="masthead">
    <div class="container">
      <div class="masthead-subheading">
        Hi {{ username && username }}, Selamat datang di Inspirasee Psikotes!
      </div>
      <div class="masthead-heading text-uppercase">
        PAPI Kostick & DISC Personality
      </div>
      <a
        class="btn btn-primary btn-xl text-uppercase"
        href="#tests"
        @click.prevent="scrollToSection('tests', $event)"
        >Psikotes</a
      >
    </div>
  </header>
</template>
<script>
export default {
  name: 'AppHeader',
  props: {
    username: {
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    scrollToSection(sectionId, event) {
      event.preventDefault()
      const section = document.getElementById(sectionId)
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<style lang=""></style>
