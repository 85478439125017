import axiosInstance from '@/services/AxiosInstance'
import {
  DISC_RESULT_USER_ACTION,
  SET_DISC_RESULT_USER
} from '@/store/storeconstants'

export default {
  async [DISC_RESULT_USER_ACTION](context, userId) {
    const { commit, state } = context
    commit(SET_DISC_RESULT_USER, {
      ...state,
      isLoading: true
    })

    try {
      const response = await axiosInstance.get(
        `/app/disc-scores/${userId}/results`
      )
      if (response.status === 200) {
        const { data } = response
        commit(SET_DISC_RESULT_USER, {
          ...state,
          results: data,
          isLoading: false
        })
      }
    } catch (error) {
      const { response } = error
      commit(SET_DISC_RESULT_USER, {
        ...state,
        error: response.data,
        isLoading: false
      })
    }
  }
}
