<template>
  <div class="signup">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Peserta</h2>
      <h3 class="section-subheading text-muted">Kirim undangan</h3>
    </div>
    <div class="row mb-4">
      <p
        :class="[
          'mb-3 text-center text-lg font-italic',
          isError ? 'text-warning' : 'text-success'
        ]"
      >
        {{ message }}
      </p>
      <div class="col-12 d-flex align-items-center justify-content-center">
        <form @submit.prevent="inviteUser">
          <div class="input-group" style="">
            <input
              class="form-control"
              type="email"
              v-model="email"
              placeholder="Ketik email pengguna ..."
              required
            />
            <button class="btn btn-primary" type="submit" :disabled="isLoading">
              Undang &nbsp;
              <span
                v-if="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button class="btn btn-danger" @click.prevent="reloadPage">
              Batal
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/services/AxiosInstance'

export default {
  data() {
    return {
      email: '',
      message: '',
      isError: false,
      isLoading: false
    }
  },
  methods: {
    async inviteUser() {
      this.isLoading = true
      try {
        const response = await axiosInstance.post('/app/register-email', {
          email: this.email
        })
        const { data } = response
        this.message = data.message
        this.isLoading = false
        this.email = ''
      } catch (error) {
        console.error(error)
        const { response } = error
        this.isLoading = false
        this.isError = true
        this.message = response.data.message
      }
    },

    reloadPage() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  .text-sm {
    font-size: small;
  }

  .font-italic {
    font-style: italic;
  }
}
</style>
