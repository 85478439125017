import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      id: '',
      user: {},
      token: '',
      errors: [],
      error: null,
      isLoading: false
    }
  },
  mutations,
  getters,
  actions
}
