<template>
  <div>
    <AppNavbar :level="user.level" />
    <AppHeader :username="user.name" />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import '../assets/css/_main.css'
import '../assets/css/_profile.css'
import AppNavbar from '@/components/AppNavbar.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AppNavbar,
    AppHeader,
    AppFooter
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user
    })
  }
}
</script>
