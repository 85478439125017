import {
  GET_PAPIKOSTICK_QUESTIONS,
  GET_PAPIKOSTICK_RESULTS,
  GET_PAPIKOSTICK_JOB_POSITION_MIN,
  GET_PAPIKOSTICK_JOB_POSITION_MAX
} from '@/store/storeconstants'

export default {
  [GET_PAPIKOSTICK_QUESTIONS]: (state) => {
    return state.questions
  },

  [GET_PAPIKOSTICK_RESULTS]: (state) => {
    return state.results
  },

  [GET_PAPIKOSTICK_JOB_POSITION_MIN]: (state) => {
    return state.jobPositionMin
  },

  [GET_PAPIKOSTICK_JOB_POSITION_MAX]: (state) => {
    return state.jobPositionMax
  }
}
