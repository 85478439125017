<template>
  <div class="container">
    <div v-if="isInvite">
      <invite-email />
    </div>
    <div v-else>
      <modal-confirm
        v-if="isModalActive"
        width="40%"
        :hideModalAction="hideConfirmationModal"
        :buttonAction="deleteItem"
        :param="itemDelete"
        modalContent="Apa kamu yakin ingin menghapus hapus peserta ini?"
      />
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Peserta</h2>
        <h3 class="section-subheading text-muted">
          Silahkan klik "aksi profil" untuk melihat informasi data peserta dan
          hasil psikotes
        </h3>
      </div>
      <div class="row align-items-stretch mb-4">
        <div class="col-md-4">
          <div class="form-group">
            <input
              class="form-control"
              id="name"
              type="text"
              data-sb-validations="required"
              v-model="searchKeyword"
              @input="search"
              placeholder="Ketik untuk mencari..."
              autocomplete="off"
            />
            <div class="invalid-feedback" data-sb-feedback="name:required">
              A name is required.
            </div>
          </div>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
          <button class="btn btn-primary text-right" @click.prevent="onInvite">
            Undang Peserta &nbsp;
            <fa :icon="['fas', 'fa-envelope']" class="text-white" />
          </button>
        </div>
      </div>

      <div class="col-md-12 col-lg-12">
        <div class="table-responsive">
          <table
            class="table table-transparent table-striped table-hover table-condensed"
          >
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col" width="30%">Nama</th>
                <th scope="col" width="25%">Email</th>
                <th scope="col">Telepon</th>
                <th scope="col">ID</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="isLoading">
                <tr>
                  <td colspan="6" class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-if="members">
                  <tr v-for="(item, index) in currentPageData" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        item.profile
                          ? item.profile.first_name +
                            ' ' +
                            item.profile.last_name
                          : item.name
                          ? item.name
                          : 'tanpa nama'
                      }}
                    </td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.id }}</td>
                    <td>
                      <div class="btn-group">
                        <a :href="`/member/${item.id}`" class="btn btn-primary">
                          Profil
                        </a>
                        <button
                          class="btn btn-danger"
                          :disabled="item.level === 'admin'"
                          @click.prevent="showConfirmationModal(item.id)"
                        >
                          Hapus
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          Halaman {{ currentPage }} dari {{ totalPages }} ({{ totalItems }}
          peserta)
        </div>
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="goToFirstPage"
                >First</a
              >
            </li>
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="prevPage">Prev</a>
            </li>
            <li
              class="page-item"
              v-for="pageNumber in displayedPages"
              :key="pageNumber"
              :class="{ active: pageNumber === currentPage }"
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="changePage(pageNumber)"
              >
                {{ pageNumber }}
              </a>
            </li>
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
            >
              <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
            </li>
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
            >
              <a class="page-link" href="#" @click.prevent="goToLastPage"
                >Last</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import modalConfirm from '@/components/modalConfirm.vue'
import {
  GET_MEMBERS_ACTION,
  DELETE_MEMBER_ACTION
} from '@/store/storeconstants'
import InviteEmail from '@/components/InviteEmail.vue'

export default {
  components: {
    InviteEmail,
    modalConfirm
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      itemsPerPage: 5,
      totalItems: 0,
      searchKeyword: '',
      isInvite: false,
      deleteMessage: '',
      isModalActive: false,
      itemDelete: null
    }
  },
  mounted() {
    this.onGetMembers()
  },
  computed: {
    ...mapState('member', {
      members: (state) => state.members,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    }),

    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },

    currentPageData() {
      const filteredItems = this.members.filter((item) => {
        return (
          (item.name &&
            item.name
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())) ||
          (item.profile &&
            item.profile.first_name
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())) ||
          (item.profile &&
            item.profile.last_name
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())) ||
          (item.email &&
            item.email
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())) ||
          (item.phone &&
            item.phone.toLowerCase().includes(this.searchKeyword.toLowerCase()))
        )
      })
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return filteredItems.slice(startIndex, endIndex)
    },
    displayedPages() {
      const pageCount = 5
      const start = Math.max(1, this.currentPage - Math.floor(pageCount / 2))
      const end = Math.min(this.totalPages, start + pageCount - 1)
      const pages = []
      for (let i = start; i <= end; i++) {
        pages.push(i)
      }
      return pages
    }
  },
  methods: {
    ...mapActions('member', {
      getMembers: GET_MEMBERS_ACTION,
      deleteMember: DELETE_MEMBER_ACTION
    }),

    async onGetMembers() {
      await this.getMembers()
      this.totalItems = this.members.length
    },

    onInvite() {
      this.isInvite = true
    },

    search() {
      this.currentPage = 1
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    goToFirstPage() {
      this.currentPage = 1
    },
    goToLastPage() {
      this.currentPage = this.totalPages
    },
    async deleteItem(id, value) {
      this.hideConfirmationModal(value)
      const response = await this.deleteMember(id)
      this.deleteMessage = response
    },
    showConfirmationModal(id) {
      this.isModalActive = true
      this.itemDelete = id
    },
    hideConfirmationModal(value) {
      this.isModalActive = value
      this.itemDelete = null
    }
  }
}
</script>

<style scoped>
.page-item.active .page-link {
  background-color: #12c6be;
  border-color: #12c6be;
}
.page-item.active .page-link:hover {
  background-color: #008f89;
  border-color: #008f89;
}
</style>
