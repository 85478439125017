<template>
  <div>
    <div class="mb-3">
      <html2-pdf
        :disabled="isLoading"
        :onGenerate="generatePdf"
        :html="html"
        :filename="filename"
      />
    </div>
    <div id="test_result">
      <div class="mb-5">
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th>Line</th>
              <th>D</th>
              <th>I</th>
              <th>S</th>
              <th>C</th>
              <th>*</th>
              <th>Tot</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{{ mostData.d }}</td>
              <td>{{ mostData.i }}</td>
              <td>{{ mostData.s }}</td>
              <td>{{ mostData.c }}</td>
              <td>{{ mostData.n }}</td>
              <td>
                {{
                  mostData.d + mostData.i + mostData.s + mostData.c + mostData.n
                }}
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>{{ leastData.d }}</td>
              <td>{{ leastData.i }}</td>
              <td>{{ leastData.s }}</td>
              <td>{{ leastData.c }}</td>
              <td>{{ leastData.n }}</td>
              <td>
                {{
                  leastData.d +
                  leastData.i +
                  leastData.s +
                  leastData.c +
                  leastData.n
                }}
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>{{ changeData.d }}</td>
              <td>{{ changeData.i }}</td>
              <td>{{ changeData.s }}</td>
              <td>{{ changeData.c }}</td>
              <td colspan="2" class="bg-black"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mb-5">
        <div class="col-4">
          <strong>Most Public Self</strong>
          <disc-score-line-chart
            :dataCharts="mostResultData"
            type="MOST"
            bgColor="rgba(245, 27, 27)"
          />
        </div>
        <div class="col-4">
          <strong>Core Private Self</strong>
          <disc-score-line-chart
            :dataCharts="leastResultData"
            type="LEAST"
            bgColor="rgb(125, 125, 125)"
          />
        </div>
        <div class="col-4">
          <strong>Mirror Perceived Self</strong>
          <disc-score-line-chart :dataCharts="changeResultData" type="CHANGE" />
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="flex-grow-1">
          <h3 class="mb-0">Gambaran Karakter</h3>
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div
              class="d-flex flex-column flex-md-row justify-content-between mb-5"
            >
              <div class="flex-grow-1">
                <div class="subheading mb-3">Mirror Perceived Self</div>
                <ul
                  class="fa-ul mb-0"
                  v-for="(item, index) in behaviours"
                  :key="index"
                >
                  <li>
                    <span class="fa-li"
                      ><fa :icon="['fas', 'fa-circle-dot']"
                    /></span>
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="d-flex flex-column flex-md-row justify-content-between mb-5"
            >
              <div class="flex-grow-1">
                <div class="subheading mb-3">Deskripsi Kepribadian</div>
                <p>
                  {{ patterns.description }}
                </p>
              </div>
            </div>
            <div
              class="d-flex flex-column flex-md-row justify-content-between mb-5"
            >
              <div class="flex-grow-1">
                <div class="subheading mb-3">Pekerjaan Cocok</div>
                <p>
                  {{ patterns.jobs }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DiscScoreLineChart from './DiscScoreLineChart.vue'
import { mapActions, mapState } from 'vuex'
import { DISC_PATTERN_ACTION } from '@/store/storeconstants'
import Html2Pdf from './Html2Pdf.vue'

export default {
  components: {
    DiscScoreLineChart,
    Html2Pdf
  },
  props: {
    scores: {
      type: Array,
      required: true
    },
    results: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mostData: null,
      leastData: null,
      changeData: null,
      mostResultData: null,
      leastResultData: null,
      changeResultData: null,
      patternId: null,
      dataPattern: null,
      behaviours: null,
      html: '',
      filename: ''
    }
  },
  created() {
    this.onGetPattern()
  },
  computed: {
    ...mapState('pattern', {
      patterns: (state) => state.patterns,
      isLoading: (state) => state.isLoading,
      error: (state) => state.error
    })
  },
  methods: {
    ...mapActions('pattern', {
      getPattern: DISC_PATTERN_ACTION
    }),

    async onGetPattern() {
      this.updateMostData()
      this.updateLeastData()
      this.updateChangeData()
      this.calculatePattern(this.changeResultData)
      await this.getPattern(this.patternId)
      this.createBehaviours()
    },

    createBehaviours() {
      this.behaviours = this.patterns.behaviour.split(',')
      this.behaviours = this.behaviours.filter((item) => item.trim() !== '')
    },

    updateMostData() {
      this.mostData = this.findDataByType(this.scores, 'most')
      this.mostResultData = this.findDataByType(this.results, 'most')
    },

    updateLeastData() {
      this.leastData = this.findDataByType(this.scores, 'least')
      this.leastResultData = this.findDataByType(this.results, 'least')
    },

    updateChangeData() {
      this.changeData = this.findDataByType(this.scores, 'change')
      this.changeResultData = this.findDataByType(this.results, 'change')
    },

    findDataByType(data, type) {
      const newData = data.find((item) => item.type === type)
      if (newData) {
        return {
          d: newData.d,
          i: newData.i,
          s: newData.s,
          c: newData.c,
          n: newData.n
        }
      }
      return { d: 0, i: 0, s: 0, c: 0, n: 0 }
    },

    calculatePattern(resultData) {
      const { d, i, s, c } = resultData

      if (d <= 0 && i <= 0 && s <= 0 && c > 0) this.patternId = 1 // c
      else if (d > 0 && i <= 0 && s <= 0 && c <= 0) this.patternId = 2 // d
      else if (d > 0 && i <= 0 && s <= 0 && c > 0 && c >= d)
        this.patternId = 3 // d/c-d
      else if (d > 0 && i > 0 && s <= 0 && c <= 0 && i >= d)
        this.patternId = 4 // d/i-d
      else if (d > 0 && i > 0 && s <= 0 && c > 0 && i >= d && d >= c)
        this.patternId = 5 // d/i-d-c
      else if (d > 0 && i > 0 && s > 0 && c <= 0 && i >= d && d >= s)
        this.patternId = 6 // d/i-d-s
      else if (d > 0 && i > 0 && s > 0 && c <= 0 && i >= s && s >= d)
        this.patternId = 7 // d/i-s-d
      else if (d > 0 && i <= 0 && s > 0 && c > 0 && s >= d && d >= c)
        this.patternId = 8 // d/s-d-c/s-c-d
      else if (d > 0 && i > 0 && s <= 0 && c <= 0 && d >= i)
        this.patternId = 9 // d-i
      else if (d > 0 && i > 0 && s > 0 && c <= 0 && d >= i && i >= s)
        this.patternId = 10 // d-i-s
      else if (d > 0 && i <= 0 && s > 0 && c <= 0 && d >= s)
        this.patternId = 11 // d-s
      else if (d <= 0 && i > 0 && s > 0 && c > 0 && c >= i && i >= s)
        this.patternId = 12 // i/c-i-s
      else if (d <= 0 && i > 0 && s > 0 && c > 0 && c >= s && s >= i)
        this.patternId = 13
      else if (d <= 0 && i > 0 && s > 0 && c > 0 && i >= s && i >= c)
        this.patternId = 14
      else if (d <= 0 && i <= 0 && s > 0 && c <= 0) this.patternId = 15
      else if (d <= 0 && i <= 0 && s > 0 && c > 0 && c >= s) this.patternId = 16
      else if (d <= 0 && i <= 0 && s > 0 && c > 0 && s >= c) this.patternId = 17
      else if (d > 0 && i <= 0 && s <= 0 && c > 0 && d >= c) this.patternId = 18
      else if (d > 0 && i > 0 && s <= 0 && c > 0 && d >= i && i >= c)
        this.patternId = 19
      else if (d > 0 && i > 0 && s > 0 && c <= 0 && d >= s && s >= i)
        this.patternId = 20
      else if (d > 0 && i <= 0 && s > 0 && c > 0 && d >= s && s >= c)
        this.patternId = 21
      else if (d > 0 && i > 0 && s <= 0 && c > 0 && d >= c && c >= i)
        this.patternId = 22
      else if (d > 0 && i <= 0 && s > 0 && c > 0 && d >= c && c >= s)
        this.patternId = 23
      else if (d <= 0 && i > 0 && s <= 0 && c <= 0) this.patternId = 24
      else if (d <= 0 && i > 0 && s > 0 && c <= 0 && i >= s) this.patternId = 25
      else if (d <= 0 && i > 0 && s <= 0 && c > 0 && i >= c) this.patternId = 26
      else if (d > 0 && i > 0 && s <= 0 && c > 0 && i >= c && c >= d)
        this.patternId = 27
      else if (d <= 0 && i > 0 && s > 0 && c > 0 && i >= c && c >= s)
        this.patternId = 28
      else if (d > 0 && i <= 0 && s > 0 && c <= 0 && s >= d) this.patternId = 29
      else if (d <= 0 && i > 0 && s > 0 && c <= 0 && s >= i) this.patternId = 30
      else if (d > 0 && i > 0 && s > 0 && c <= 0 && s >= d && d >= i)
        this.patternId = 31
      else if (d > 0 && i > 0 && s > 0 && c <= 0 && s >= i && i >= d)
        this.patternId = 32
      else if (d <= 0 && i > 0 && s > 0 && c > 0 && s >= i && i >= c)
        this.patternId = 33
      else if (d > 0 && i <= 0 && s > 0 && c > 0 && s >= c && c >= d)
        this.patternId = 34
      else if (d <= 0 && i > 0 && s > 0 && c > 0 && s >= c && c >= i)
        this.patternId = 35
      else if (d <= 0 && i > 0 && s <= 0 && c > 0 && c >= i) this.patternId = 36
      else if (d > 0 && i > 0 && s <= 0 && c > 0 && c >= d && d >= i)
        this.patternId = 37
      else if (d > 0 && i <= 0 && s > 0 && c > 0 && c >= d && d >= s)
        this.patternId = 38
      else if (d > 0 && i > 0 && s <= 0 && c > 0 && c >= i && i >= d)
        this.patternId = 39
      else if (d > 0 && i <= 0 && s > 0 && c > 0 && c >= s && s >= d)
        this.patternId = 40
      else this.patternId = 0 // default pattern
    },

    generatePdf() {
      this.filename = `DISC Hasil ${this.name}`
      const elementSelected = document.querySelector('#test_result')
      const canvases = elementSelected.querySelectorAll('canvas')

      const newDiv = document.createElement('div')
      newDiv.innerHTML = elementSelected.innerHTML
      const newCanvases = newDiv.querySelectorAll('canvas')

      canvases.forEach((canvas, index) => {
        const imageData = canvas.toDataURL('image/png', 1.0)
        const img = document.createElement('img')
        img.src = imageData

        const newCanvas = newCanvases[index]
        newCanvas.parentNode.replaceChild(img, newCanvas)
      })

      this.html = newDiv.innerHTML
    }
  }
}
</script>
