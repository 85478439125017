<template>
  <button class="btn btn-primary" @click="downloadPdf">
    <fa :icon="['fas', 'file-pdf']" />
    PDF
  </button>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  props: {
    onGenerate: {
      type: Function,
      required: true
    },
    html: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    }
  },
  methods: {
    async downloadPdf() {
      await this.onGenerate()

      var opt = {
        margin: 0.05,
        filename: `${this.filename}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      }

      html2pdf().from(this.html).set(opt).save()
    }
  }
}
</script>
