import { createStore } from 'vuex'
import auth from './modules/auth'
import member from './modules/member'
import papikostick from './modules/papikostick'
import disc from './modules/disc'
import discResult from './modules/discResult'
import pattern from './modules/pattern'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    member,
    papikostick,
    disc,
    discResult,
    pattern
  }
})
