import Validations from './Validations'

export default class LoginValidations {
  constructor(email, password) {
    ;(this.email = email), (this.password = password)
  }

  checkValidations() {
    let error = []

    if (!Validations.checkEmail(this.email)) {
      error['email'] = 'Invalid Email'
    }

    if (!Validations.minLength(this.password, 6)) {
      error['password'] = 'Kata sandi harus berisi minimal 6 karakter'
    }

    return error
  }

  static getErrorMessageFromCode(errorCode) {
    switch (errorCode) {
      case 'Email Exists':
        return 'Email sudah digunakan!'
      case 'Password Wrong':
        return 'Kata sandi salah!'
      case 'Email Not Found':
        return 'Email tidak ditemukan!'
      default:
        return 'Ada yang salah!'
    }
  }
}
