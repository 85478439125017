import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      questions: null,
      results: null,
      isLoading: false,
      error: null,
      jobPositionMin: [
        {
          code: 'N',
          admin: '6',
          produksi: '4',
          frontliner: '4',
          kurir: '6'
        },
        {
          code: 'G',
          admin: '5',
          produksi: '5',
          frontliner: '5',
          kurir: '5'
        },
        {
          code: 'A',
          admin: '4',
          produksi: '4',
          frontliner: '6',
          kurir: '4'
        },
        {
          code: 'L',
          admin: '1',
          produksi: '1',
          frontliner: '1',
          kurir: '1'
        },
        {
          code: 'P',
          admin: '1',
          produksi: '1',
          frontliner: '1',
          kurir: '1'
        },
        {
          code: 'I',
          admin: '3',
          produksi: '3',
          frontliner: '5',
          kurir: '3'
        },
        {
          code: 'T',
          admin: '5',
          produksi: '5',
          frontliner: '5',
          kurir: '5'
        },
        {
          code: 'V',
          admin: '1',
          produksi: '5',
          frontliner: '5',
          kurir: '5'
        },
        {
          code: 'O',
          admin: '4',
          produksi: '4',
          frontliner: '5',
          kurir: '4'
        },
        {
          code: 'B',
          admin: '1',
          produksi: '4',
          frontliner: '4',
          kurir: '1'
        },
        {
          code: 'S',
          admin: '4',
          produksi: '4',
          frontliner: '6',
          kurir: '4'
        },
        {
          code: 'X',
          admin: '2',
          produksi: '2',
          frontliner: '3',
          kurir: '2'
        },
        {
          code: 'C',
          admin: '6',
          produksi: '4',
          frontliner: '3',
          kurir: '4'
        },
        {
          code: 'D',
          admin: '7',
          produksi: '5',
          frontliner: '5',
          kurir: '5'
        },
        {
          code: 'R',
          admin: '5',
          produksi: '1',
          frontliner: '1',
          kurir: '1'
        },
        {
          code: 'Z',
          admin: '3',
          produksi: '3',
          frontliner: '3',
          kurir: '3'
        },
        {
          code: 'E',
          admin: '2',
          produksi: '2',
          frontliner: '2',
          kurir: '2'
        },
        {
          code: 'K',
          admin: '3',
          produksi: '3',
          frontliner: '3',
          kurir: '3'
        },
        {
          code: 'F',
          admin: '5',
          produksi: '4',
          frontliner: '4',
          kurir: '4'
        },
        {
          code: 'W',
          admin: '1',
          produksi: '1',
          frontliner: '1',
          kurir: '1'
        }
      ],
      jobPositionMax: [
        {
          code: 'N',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'G',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'A',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'L',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'P',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'I',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'T',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'V',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'O',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'B',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'S',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'X',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'C',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'D',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'R',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'Z',
          admin: '6',
          produksi: '6',
          frontliner: '6',
          kurir: '6'
        },
        {
          code: 'E',
          admin: '6',
          produksi: '6',
          frontliner: '6',
          kurir: '6'
        },
        {
          code: 'K',
          admin: '5',
          produksi: '5',
          frontliner: '5',
          kurir: '5'
        },
        {
          code: 'F',
          admin: '9',
          produksi: '9',
          frontliner: '9',
          kurir: '9'
        },
        {
          code: 'W',
          admin: '5',
          produksi: '5',
          frontliner: '5',
          kurir: '5'
        }
      ]
    }
  },
  mutations,
  getters,
  actions
}
