<template>
  <section class="resume-section">
    <div class="resume-section-content">
      <h2 class="mb-5">PAPI Kostick</h2>
      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="flex-grow-1">
          <div class="subheading mb-3">Petunjuk</div>
          <p>
            Test ini ada 90 nomor isian. Masing-masing nomor memiliki dua
            pernyataan (Pernyataan 1 & 2). Pilihlah salah satu pernyataan yang
            paling sesuai dengan diri Anda dengan men-check pada isian pada
            kolom yang sudah disediakan (Kolom Pilihan). Anda HARUS memilih
            salah satu yang dominan serta mengisi semua nomor.
          </p>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="flex-grow-1">
          <div class="subheading mb-3">Contoh Tes</div>
          <table class="table table-primary">
            <tbody>
              <tr>
                <th width="5%">No</th>
                <th width="15%" class="text-center">Pilihan</th>
                <th>Pernyataan</th>
              </tr>
            </tbody>
          </table>
          <table class="table table-condensed">
            <tbody>
              <tr>
                <td rowspan="2" width="5%" class="text-center align-middle">
                  1
                </td>
                <td width="15%" class="text-center border-bottom-0 py-2 px-0">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" checked />
                  </div>
                </td>
                <td class="border-bottom-0">Saya biasanya bekerja keras</td>
              </tr>
              <tr>
                <td width="15%" class="text-center py-2 px-0">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" disabled />
                  </div>
                </td>
                <td>Saya biasanya bekerja cepat</td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn btn-primary text-uppercase"
            @click.prevent="onStart()"
          >
            Mulai Tes
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PapikostickView',
  data() {
    return {
      pathname: 'me'
    }
  },
  mounted() {
    let { name } = this.$route
    if (name.includes('me')) this.pathname = 'me'
    else this.pathname = 'member'
  },
  methods: {
    onStart() {
      this.$router.replace(`/${this.pathname}/papikostick/test`)
    }
  }
}
</script>

<style lang="scss" scoped>
input[type='radio'] {
  font-size: 22px;
}

.form-check .form-check-input {
  float: none;
}
</style>
