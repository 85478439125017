<template>
  <div class="modal is-active">
    <div class="modal-content" :style="{ width: width }">
      {{ modalContent }}
      <div class="btn-group mt-3">
        <button class="btn btn-danger" @click.prevent="handleAction()">
          Ya
        </button>
        <button
          class="btn btn-secondary"
          aria-label="close"
          @click="handleHideModal"
        >
          Tidak
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    hideModalAction: {
      type: Function,
      required: true
    },
    modalContent: {
      type: String,
      required: true
    },
    buttonAction: {
      type: Function,
      required: true
    },
    param: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleAction() {
      this.buttonAction(this.param, false)
    },
    handleHideModal() {
      this.hideModalAction(false)
    }
  }
}
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.is-active {
  display: block !important;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
</style>
