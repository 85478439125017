<template>
  <div>
    <div class="mb-3">
      <html2-pdf :onGenerate="generatePdf" :html="html" :filename="filename" />
    </div>
    <div id="papikostic_result">
      <ul class="list-group mb-5">
        <li
          class="list-group-item list-group-item-success"
          v-for="result in results"
          :key="result.id"
        >
          <strong>{{ result.aspect }}</strong>
          <ul class="list-group">
            <li
              class="list-group-item list-group-item-success"
              style="border: none"
              v-for="role in result.roles"
              :key="role.id"
            >
              <strong>{{ role.role }}</strong>
              <ol class="list-group list-group-numbered">
                <li
                  class="list-group-item d-flex justify-content-between align-items-start list-group-item-success"
                  style="border: none"
                  v-for="description in role.descriptions"
                  :key="description.id"
                >
                  <div class="ms-2 me-auto">
                    {{ description.description }}
                  </div>
                </li>
              </ol>
              <div v-if="role.results">
                <div v-for="rule in role.rules" :key="rule.id">
                  <p
                    class="mb-1"
                    v-if="
                      parseInt(role.results.value) >=
                        parseInt(rule.low_value) &&
                      parseInt(role.results.value) <= parseInt(rule.high_value)
                    "
                  >
                    <span class="bg-warning"
                      >Poin: {{ role.results.value }} ({{
                        rule.interpretation
                      }})</span
                    >
                  </p>
                </div>
              </div>
              <div v-else>
                <div v-for="rule in role.rules" :key="rule.id">
                  <p
                    class="mb-1"
                    v-if="
                      parseInt(0) >= parseInt(rule.low_value) &&
                      parseInt(0) <= parseInt(rule.high_value)
                    "
                  >
                    <span class="bg-warning"
                      >Poin: 0 ({{ rule.interpretation }})</span
                    >
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div class="html2pdf__page-break"></div>
        </li>
      </ul>

      <div
        class="mb-5"
        v-for="(jobPosition, index) in jobPositions"
        :key="index"
      >
        <div class="p-4 border rounded-2">
          <div class="d-flex align-items-center px-4 mb-5">
            <div class="logo">
              <img
                class=""
                src="../assets/img/Inspirasee.png"
                alt="Logo"
                style="width: 54%"
              />
            </div>
            <div class="col-lg-8">
              <div class="subheading text-center">
                Ringkasan Pemeriksaan Papikostick
              </div>
              <div class="text-bold text-center">
                Posisi - {{ jobPosition.name }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="text-bold">
                {{
                  member.profile
                    ? `${member.profile.first_name} ${member.profile.last_name}`
                    : member.name
                }}
              </div>
              <p>Kesesuaian dengan posisi ini: {{ jobPosition.passing }}%</p>
              <div class="panel-passing">
                <div class="text-bold mb-2 panel-passing-title">
                  Kesesuaian dengan posisi lainnya
                </div>
                <div class="panel-passing-body">
                  <div v-for="(pos, id) in jobPositions" :key="id">
                    <span>{{ pos.name }}</span>
                    <p class="" style="font-size: 18px; font-weight: bold">
                      {{ pos.passing }}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg">
              <div class="panel-chart">
                <img
                  src="../assets/img/Inspirasee.png"
                  alt="Bg Logo"
                  class="bg-logo"
                />
                <div class="panel-chart-body">
                  <the-chart
                    :results="dataCharts"
                    :jobPosition="jobPosition.name"
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 text-justify">
            <div class="border bg-success bg-opacity-50 passing-memo">
              <div
                class="p-2 bg-white rounded-3 passing-memo-body"
                style="font-size: 0.8rem"
              >
                Hasil tes PAPI Kostick ini hanya menggambarkan kepribadian
                kandidat dan kesesuaiannya dengan jenis pekerjaan yang
                ditawarkan. Hasil tes ini tidak menggambarkan kompetensi dan
                pengalaman kandidat dalam melakukan suatu pekerjaan tertentu
              </div>
            </div>
          </div>
        </div>

        <div class="html2pdf__page-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TheChart from './TheChart.vue'
import Html2Pdf from './Html2Pdf.vue'
import { mapGetters } from 'vuex'
import {
  GET_PAPIKOSTICK_JOB_POSITION_MAX,
  GET_PAPIKOSTICK_JOB_POSITION_MIN
} from '@/store/storeconstants'

export default {
  name: 'ResultView',
  props: {
    results: {
      type: Array,
      required: true
    },
    member: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataCharts: [],
      html: '',
      filename: '',
      jobPositions: [
        {
          name: 'Admin',
          passing: 0
        },
        {
          name: 'Produksi',
          passing: 0
        },
        {
          name: 'Frontliner',
          passing: 0
        },
        {
          name: 'Kurir',
          passing: 0
        }
      ]
    }
  },
  components: {
    TheChart,
    Html2Pdf
  },
  computed: {
    ...mapGetters('papikostick', {
      jobPositionMin: GET_PAPIKOSTICK_JOB_POSITION_MIN,
      jobPositionMax: GET_PAPIKOSTICK_JOB_POSITION_MAX
    })
  },
  mounted() {
    this.onGetResult()
  },
  methods: {
    async onGetResult() {
      await this.formatDataGet(this.results)
    },

    formatDataGet(data) {
      let roles = []

      data.forEach((el) => {
        roles = roles.concat(el.roles)
      })

      this.dataCharts = Object.fromEntries(
        roles.map((el) => [
          el.id,
          {
            aspect_id: el.papi_aspect_id,
            label_code: el.code,
            label: `${el.role.split(' - ')[0].trim()} - (${
              el.results ? el.results.value : 0
            })`,
            value: el.results ? el.results.value : 0
          }
        ])
      )

      this.jobPositionMin.forEach((item) => {
        for (let key in this.dataCharts) {
          if (this.dataCharts[key].label_code === item.code) {
            this.dataCharts[key].admin = item.admin
            this.dataCharts[key].produksi = item.produksi
            this.dataCharts[key].frontliner = item.frontliner
            this.dataCharts[key].kurir = item.kurir
          }
        }
      })

      this.jobPositionMax.forEach((item) => {
        for (let key in this.dataCharts) {
          if (this.dataCharts[key].label_code === item.code) {
            this.dataCharts[key].admin_max = item.admin
            this.dataCharts[key].produksi_max = item.produksi
            this.dataCharts[key].frontliner_max = item.frontliner
            this.dataCharts[key].kurir_max = item.kurir
          }
        }
      })

      this.countPassingPresentage(this.dataCharts)
    },

    updateJobPosition(passingData) {
      let self = this
      passingData.forEach(function (item) {
        for (var key in item) {
          var positionName = key
          var positionValue = item[key]

          var positionIndex = self.jobPositions.findIndex(function (pos) {
            return pos.name.toLowerCase() === positionName
          })

          if (positionIndex !== -1) {
            self.jobPositions[positionIndex].passing = positionValue
          }
        }
      })
    },

    countPassingPresentage(data) {
      let totalCount = 0
      let adminCount = 0
      let produksiCount = 0
      let frontlinerCount = 0
      let kurirCount = 0

      for (let key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          let value = parseInt(data[key].value)
          let admin = parseInt(data[key].admin)
          let adminMax = parseInt(data[key].admin_max)
          let produksi = parseInt(data[key].produksi)
          let produksiMax = parseInt(data[key].produksi_max)
          let frontliner = parseInt(data[key].frontliner)
          let frontlinerMax = parseInt(data[key].frontliner_max)
          let kurir = parseInt(data[key].kurir)
          let kurirMax = parseInt(data[key].kurir_max)

          if (value >= admin && value <= adminMax) {
            adminCount++
          }
          if (value >= produksi && value <= produksiMax) {
            produksiCount++
          }
          if (value >= frontliner && value <= frontlinerMax) {
            frontlinerCount++
          }
          if (value >= kurir && value <= kurirMax) {
            kurirCount++
          }
          totalCount++
        }
      }

      let adminPercentage = (adminCount / totalCount) * 100
      let produksiPercentage = (produksiCount / totalCount) * 100
      let frontlinerPercentage = (frontlinerCount / totalCount) * 100
      let kurirPercentage = (kurirCount / totalCount) * 100

      let passingData = []

      passingData.push(
        { admin: adminPercentage.toFixed(0) },
        { produksi: produksiPercentage.toFixed(0) },
        { frontliner: frontlinerPercentage.toFixed(0) },
        { kurir: kurirPercentage.toFixed(0) }
      )

      this.updateJobPosition(passingData)
    },

    generatePdf() {
      this.filename = `PAPIKOSTICK Hasil ${
        this.member.name === null ? this.member.name : this.member.email
      }`
      const elementSelected = document.querySelector('#papikostic_result')
      const canvases = elementSelected.querySelectorAll('canvas')

      const newDiv = document.createElement('div')
      newDiv.innerHTML = elementSelected.innerHTML
      const newCanvases = newDiv.querySelectorAll('canvas')

      canvases.forEach((canvas, index) => {
        const imageData = canvas.toDataURL('image/png', 1.0)
        const img = document.createElement('img')
        img.src = imageData

        const newCanvas = newCanvases[index]
        newCanvas.parentNode.replaceChild(img, newCanvas)
      })

      this.html = newDiv.innerHTML
    }
  }
}
</script>

<style lang="scss" scoped>
table td {
  border: none;
}
</style>
