import {
  SET_USER_REGISTER,
  SET_USER_TOKEN_MUTATION,
  SET_USER_ERROR_MUTATION,
  SET_USER_ERRORS_MUTATION,
  SET_USER_LOADING_MUTATION
} from '@/store/storeconstants'
export default {
  [SET_USER_REGISTER]() {},
  [SET_USER_TOKEN_MUTATION](state, payload) {
    state.id = payload.id
    state.user = payload.user
    state.token = payload.token
  },
  [SET_USER_ERROR_MUTATION](state, errorMessage) {
    state.error = errorMessage
  },
  [SET_USER_ERRORS_MUTATION](state, errorMessage) {
    state.errors = errorMessage
  },
  [SET_USER_LOADING_MUTATION](state, isLoading) {
    state.isLoading = isLoading
  }
}
