<template>
  <div
    class="the-chart d-flex flex-column align-items-center"
    style="height: 520px"
  >
    <canvas class="my-5" :id="`${jobPosition}Chart`"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
export default {
  name: 'TheChart',
  props: {
    results: {
      required: true,
      type: Object
    },
    jobPosition: {
      required: true,
      type: String
    }
  },
  watch: {
    results(objectResults) {
      let labels = [],
        values = [],
        jobPositionMin = [],
        jobPositionMax = [],
        keyJob = this.jobPosition.toLowerCase(),
        keyJobMax = `${this.jobPosition.toLowerCase()}_max`

      Object.keys(objectResults).map((key) => {
        labels.push(objectResults[key].label)
        values.push(objectResults[key].value)
        jobPositionMin.push(objectResults[key][keyJob])
        jobPositionMax.push(objectResults[key][keyJobMax])
      })

      const ctx = document.getElementById(`${this.jobPosition}Chart`)

      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Poin Peserta',
            data: values,
            fill: false,
            backgroundColor: 'rgba(28, 112, 214, 0.2)',
            borderColor: 'rgb(28, 112, 214)',
            pointBackgroundColor: 'rgb(28, 112, 214)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(28, 112, 214)',
            borderDash: [5, 5],
            pointRadius: 4
          },
          {
            label: `Poin ${this.jobPosition} Max`,
            data: jobPositionMax,
            fill: true,
            backgroundColor: 'rgba(16, 201, 50, 0.2)',
            borderColor: 'rgb(16, 201, 50)',
            pointBackgroundColor: 'rgb(16, 201, 50)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(16, 201, 50)',
            showLegend: false
          },
          {
            label: `Poin ${this.jobPosition} Min`,
            data: jobPositionMin,
            fill: true,
            backgroundColor: 'rgb(255, 255, 255)',
            borderColor: 'rgb(16, 201, 50)',
            pointBackgroundColor: 'rgb(16, 201, 50)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(16, 201, 50)',
            showLegend: false
          }
        ]
      }

      const temp = data.datasets[1]
      data.datasets[1] = data.datasets[2]
      data.datasets[2] = temp

      const config = {
        type: 'radar',
        data: data,
        options: {
          plugins: {
            legend: {
              labels: {
                generateLabels: function (chart) {
                  const original =
                    Chart.defaults.plugins.legend.labels.generateLabels
                  const labelsOriginal = original.call(this, chart)

                  // Filter out labels based on the showLegend property
                  return labelsOriginal.filter((label) => {
                    return (
                      chart.data.datasets[label.datasetIndex].showLegend !==
                      false
                    )
                  })
                }
              }
            }
          },
          elements: {
            line: {
              borderWidth: 2
            }
          },
          scales: {
            r: {
              angleLines: {
                display: true
              },
              suggestedMin: 0,
              suggestedMax: 9,
              pointLabels: {
                font: {
                  size: 10 // Ubah ukuran huruf label poin
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
      let radar = new Chart(ctx, config)

      radar.update()
    }
  }
}
</script>
