import axiosInstance from '@/services/AxiosInstance'
import { DISC_PATTERN_ACTION, SET_DISC_PATTERN } from '@/store/storeconstants'

export default {
  async [DISC_PATTERN_ACTION](context, id) {
    const { commit, state } = context
    commit(SET_DISC_PATTERN, {
      ...state,
      isLoading: true
    })

    try {
      const response = await axiosInstance.get(`/app/disc-patterns/${id}`)
      if (response.status === 200) {
        const { data } = response
        commit(SET_DISC_PATTERN, {
          ...state,
          patterns: data,
          isLoading: false
        })
      }
    } catch (error) {
      const { response } = error
      commit(SET_DISC_PATTERN, {
        ...state,
        error: response.data,
        isLoading: false
      })
    }
  }
}
