<template>
  <section class="page-section" id="tests">
    <tests-view />
  </section>
  <section
    class="page-section bg-light"
    id="members"
    v-if="user.level === 'admin'"
  >
    <member-view />
  </section>
</template>

<script>
import MemberView from './sections/MemberView.vue'
import TestsView from './sections/TestsView.vue'
import { mapState } from 'vuex'

export default {
  components: {
    MemberView,
    TestsView
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user
    })
  }
}
</script>
