/* eslint-disable */
import router from '@/router'
import axiosInstance from '@/services/AxiosInstance'
import LoginValidations from '@/services/LoginValidations'
import SignupValidations from '@/services/SignupValidations'
import UserProfileValidations from '@/services/UserProfileValidations'
import {
  AUTH_ACTION,
  LOGIN_ACTION,
  AUTH_LOGIN_ACTION,
  SET_USER_REGISTER,
  SET_USER_TOKEN_MUTATION,
  SIGNUP_ACTION,
  LOGOUT_ACTION,
  CREATE_USER_PROFILE_ACTION,
  SET_USER_ERRORS_MUTATION,
  SET_USER_LOADING_MUTATION,
  SET_USER_ERROR_MUTATION
} from '@/store/storeconstants'

export default {
  async [LOGOUT_ACTION](context) {
    localStorage.removeItem('userData')
    context.commit(SET_USER_TOKEN_MUTATION, {
      id: null,
      user: {},
      token: null
    })

    router.replace('/login')
  },

  async [SIGNUP_ACTION](context, payload) {
    let postData = {
      username: payload.name,
      email: payload.email,
      phone: payload.phone,
      password: payload.password
    }

    try {
      let response = await axiosInstance.post(`/register`, postData)
      if (response.status === 200) {
        let { data } = response
        let { user } = data

        let tokenData = {
          ...context.state,
          user
        }

        localStorage.setItem('userData', JSON.stringify(tokenData))
        context.commit(SET_USER_REGISTER, tokenData)
      }
    } catch (error) {
      let { data } = error.response
      let errorMessage = SignupValidations.getErrorMessageFromCode(data.message)
      throw errorMessage
    }
  },

  async [LOGIN_ACTION](context, payload) {
    return context.dispatch(AUTH_ACTION, {
      ...payload,
      url: `/login`
    })
  },

  async [AUTH_LOGIN_ACTION](context, payload) {
    let userData = localStorage.getItem('userData')
    if (userData) {
      context.commit(SET_USER_TOKEN_MUTATION, JSON.parse(userData))
    }
  },

  async [AUTH_ACTION](context, payload) {
    let postData = {
      email: payload.email,
      password: payload.password
    }

    try {
      let response = await axiosInstance.post(payload.url, postData)
      if (response.status === 200) {
        let { data } = response
        let { token, user } = data

        let tokenData = {
          id: user.id,
          token,
          user: user
        }

        if (user.status == '0') {
          alert('Akun anda sudah tidak aktif')
          router.replace('/login')
        } else {
          localStorage.setItem('userData', JSON.stringify(tokenData))
          context.commit(SET_USER_TOKEN_MUTATION, tokenData)
        }
      }
    } catch (error) {
      let { data } = error.response
      let errorMessage = LoginValidations.getErrorMessageFromCode(data.message)
      throw errorMessage
    }
  },

  async [CREATE_USER_PROFILE_ACTION](context, payload) {
    const { commit, state } = context
    const { name, first_name, phone } = payload

    // Validate input
    let validations = new UserProfileValidations(name, first_name, phone)

    commit(SET_USER_ERRORS_MUTATION, validations.checkValidations())

    if (Object.keys(state.errors).length) {
      return false
    }

    commit(SET_USER_LOADING_MUTATION, true)

    try {
      const response = await axiosInstance.post('/app/user-profiles', payload)
      const { data } = response
      const { user, user_profile } = data

      let dataUser = localStorage.getItem('userData')
      let parseDataUser = JSON.parse(dataUser)
      parseDataUser.user.name = user.name
      parseDataUser.user.phone = user.phone
      parseDataUser.user.profile = user_profile
      localStorage.setItem('userData', JSON.stringify(parseDataUser))
      commit(SET_USER_TOKEN_MUTATION, {
        ...state,
        user: parseDataUser.user
      })
      commit(SET_USER_LOADING_MUTATION, false)
      router.replace('/me')
    } catch (error) {
      const { response } = error
      const errorMessage = response ? response.data.message : 'Network Error'
      commit(SET_USER_ERROR_MUTATION, errorMessage)
      commit(SET_USER_LOADING_MUTATION, false)
    }
  }
}
