import { SET_MEMBERS_STATE, SET_MEMBERS_ERROR } from '@/store/storeconstants'
export default {
  [SET_MEMBERS_STATE](state, payload) {
    state.members = payload.members
    state.isLoading = payload.isLoading
    state.error = payload.error
  },

  [SET_MEMBERS_ERROR](state, errorMessage) {
    state.error = errorMessage
  }
}
