/* eslint-disable prettier/prettier */
import store from "../store/index";
import { GET_USER_TOKEN_GETTER } from "@/store/storeconstants";
import Axios from "axios";

const axiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

axiosInstance.interceptors.request.use((config) => {
  let token = store.getters[`auth/${GET_USER_TOKEN_GETTER}`];
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
