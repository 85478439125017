import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      results: null,
      isLoading: false,
      error: null
    }
  },
  mutations,
  actions
}
