<template>
  <section class="resume-section">
    <div class="resume-section-content">
      <h2 class="mb-5">DISC Personality</h2>
      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="flex-grow-1">
          <div class="subheading mb-3">Informasi</div>
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div v-if="scores && results">
              <div v-if="pathname === 'me'">
                Terima kasih Anda sudah berhasil melakukan tes disc personality
                <br />Hasil tes hanya bisa dilihat oleh admin kami!
              </div>
              <p v-else>
                <disc-result-score
                  :scores="scores"
                  :results="results"
                  :name="member.name"
                />
              </p>
            </div>
            <div v-else>
              <div v-if="pathname === 'me'">
                <p>Anda belum pernah melakukan test ini!</p>
                <a
                  class="btn btn-primary text-uppercase"
                  :href="`/${pathname}/disc/personalities`"
                >
                  Mulai Test
                </a>
              </div>
              <p v-else>
                <span class="text-primary text-capitalize">{{
                  member && member.username
                }}</span>
                belum pernah melakukan test!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  DISC_RESULT_USER_ACTION,
  DISC_SCORE_USER_ACTION
} from '@/store/storeconstants'
import DiscResultScore from '@/components/DiscResultScore.vue'

export default {
  components: {
    DiscResultScore
  },
  data() {
    return {
      pathname: 'me',
      member: null,
      memberId: null
    }
  },
  mounted() {
    this.onGetScores()
    this.onGetScoreResult()
    this.getMember()
  },

  computed: {
    ...mapState('auth', {
      id: (state) => state.id
    }),

    ...mapState('discResult', {
      results: (state) => state.results,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    }),

    ...mapState('disc', {
      scores: (state) => state.scores,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    }),

    ...mapState('member', {
      members: (state) => state.members
    })
  },

  methods: {
    ...mapActions('disc', {
      getScoreUser: DISC_SCORE_USER_ACTION
    }),

    ...mapActions('discResult', {
      getScoreResult: DISC_RESULT_USER_ACTION
    }),

    getMember() {
      if (this.pathname !== 'me') {
        const { params } = this.$route
        const id = params.id
        this.member = this.members.find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      }
    },

    async onGetScores() {
      let { name, params } = this.$route
      if (name.includes('me')) this.pathname = 'me'
      else this.pathname = 'member'

      if (this.pathname === 'me') this.memberId = this.id
      else this.memberId = params.id

      await this.getScoreUser(this.memberId)
    },

    async onGetScoreResult() {
      let { name, params } = this.$route
      if (name.includes('me')) this.pathname = 'me'
      else this.pathname = 'member'

      if (this.pathname === 'me') this.memberId = this.id
      else this.memberId = params.id

      await this.getScoreResult(this.memberId)
    }
  }
}
</script>
<style lang=""></style>
