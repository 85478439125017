import Validations from './Validations'

export default class UserProfileValidations {
  constructor(name, first_name, phone) {
    ;(this.name = name), (this.first_name = first_name), (this.phone = phone)
  }

  checkValidations() {
    let error = []

    if (!Validations.checkFill(this.name)) {
      error['name'] = 'nama pengguna harus diisi'
    }

    if (!Validations.checkFill(this.first_name)) {
      error['first_name'] = 'nama awal harus diisi'
    }

    if (!Validations.checkPhone(this.phone)) {
      error['phone'] = 'nomor telepon tidak valid'
    }

    return error
  }
}
