import {
  SET_PAPIKOSTICK_STATE,
  SET_PAPIKOSTICK_ERROR
} from '@/store/storeconstants'
export default {
  [SET_PAPIKOSTICK_STATE](state, payload) {
    state.questions = payload.questions
    state.results = payload.results
    state.isLoading = payload.isLoading
    state.error = payload.error
  },

  [SET_PAPIKOSTICK_ERROR](state, errorMessage) {
    state.error = errorMessage
  }
}
