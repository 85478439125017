<template>
  <div>
    <ProfileNavbar v-if="dataUser" :username="dataUser.name" />
    <div class="container-fluid p-0">
      <router-view />
    </div>
  </div>
</template>
<script>
import ProfileNavbar from '@/components/ProfileNavbar.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ProfileNavbar
  },
  data() {
    return {
      dataUser: null,
      pathname: 'me'
    }
  },
  mounted() {
    document.body.classList.add('second')
    this.fetchDataUser()
  },
  unmounted() {
    document.body.classList.remove('second')
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user
    }),

    ...mapState('member', {
      members: (state) => state.members
    })
  },
  methods: {
    fetchDataUser() {
      const { params, name } = this.$route
      const id = params.id

      if (name.includes('me')) this.pathname = 'me'
      else this.pathname = 'member'

      this.dataUser = this.user

      if (id !== undefined) {
        const member = this.members.find(
          (item) => parseInt(item.id) === parseInt(id)
        )

        if (this.pathname !== 'me') this.dataUser = member
      }
    }
  }
}
</script>
