<template>
  <div class="">
    <div class="text-center">
      <div>
        <img
          :src="require('../assets/img/notfound.svg')"
          style="width: 35%"
          alt="Image"
          class="mb-3"
        />
      </div>
      <button class="btn btn-primary" @click.prevent="backpage">
        <fa :icon="['fas', 'fa-left-long']" class="text-white" /> &nbsp; Kembali
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    backpage() {
      this.$router.back()
    }
  }
}
</script>
<style lang=""></style>
