<template>
  <section class="resume-section">
    <div class="resume-section-content">
      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div class="flex-grow-1">
          <h3 class="mb-5">Informasi Tentang Anda</h3>
          <div class="error text-danger" v-if="error">
            {{ error }}
          </div>
          <form @submit.prevent="onSubmit()">
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 mb-3">
              <label for="name" class="font-bold">Nama Pengguna</label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                v-model="name"
                autofocus
              />
              <div class="error text-danger" v-if="errors.name">
                {{ errors.name }}
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 mb-3">
              <label for="first_name" class="font-bold">Nama Lengkap</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-capitalize"
                  placeholder="Nama awal anda ..."
                  id="first_name"
                  name="first_name"
                  v-model="first_name"
                />
                <input
                  type="text"
                  class="form-control text-capitalize"
                  placeholder="Nama akhir anda ..."
                  id="last_name"
                  name="last_name"
                  v-model="last_name"
                />
              </div>
              <div class="error text-danger" v-if="errors.first_name">
                {{ errors.first_name }}
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 mb-3">
              <label for="address" class="font-bold">Alamat</label>
              <textarea
                type="text"
                class="form-control text-capitalize"
                id="address"
                name="address"
                v-model="address"
                style="resize: none"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 mb-3">
              <label for="phone" class="font-bold">Nomor Handphone</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                v-model="phone"
              />
              <div class="error text-danger" v-if="errors.phone">
                {{ errors.phone }}
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary mt-3"
              :disabled="isLoading"
            >
              Simpan &nbsp;
              <span
                v-if="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { CREATE_USER_PROFILE_ACTION } from '@/store/storeconstants'

export default {
  data() {
    return {
      name: '',
      first_name: '',
      last_name: '',
      address: '',
      phone: ''
    }
  },
  mounted() {
    this.checkInformationProfile()
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
      errors: (state) => state.errors,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    })
  },
  methods: {
    ...mapActions('auth', {
      createProfile: CREATE_USER_PROFILE_ACTION
    }),

    async onSubmit() {
      await this.createProfile({
        name: this.name,
        first_name: this.first_name,
        last_name: this.last_name,
        address: this.address,
        phone: this.phone,
        user_id: this.user.id
      })
    },

    checkInformationProfile() {
      if (this.user.profile) {
        this.name = this.user.name
        this.first_name = this.user.profile.first_name
        this.last_name = this.user.profile.last_name
        this.address = this.user.profile.address
        this.phone = this.user.profile.phone
      }
    }
  }
}
</script>
