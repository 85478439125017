<template>
  <section class="vh-100 py-4">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img
            :src="require('../assets/img/login.svg')"
            style="width: 100%"
            alt="Sample image"
          />
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <div class="error" v-if="error">{{ error }}</div>
          <form @submit.prevent="onLogin()" class="mb-5">
            <!-- Email input -->
            <div class="form-outline mb-4">
              <input
                type="email"
                class="form-control form-control-lg"
                placeholder="Enter a valid email address"
                id="email"
                v-model="email"
                required
              />
              <label class="form-label" for="email">Email address</label>
              <div class="error" v-if="errors.email">{{ errors.email }}</div>
            </div>

            <!-- Password input -->
            <div class="form-outline mb-3">
              <input
                type="password"
                class="form-control form-control-lg"
                placeholder="Enter password"
                id="password"
                v-model="password"
                required
              />
              <label class="form-label" for="password">Password</label>
              <div class="error" v-if="errors.password">
                {{ errors.password }}
              </div>
            </div>

            <div class="text-center text-lg-start mt-4 pt-2">
              <button
                type="submit"
                class="btn btn-primary btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem"
                :disabled="isLoading"
              >
                Login &nbsp;
                <span
                  v-if="isLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { LOGIN_ACTION } from '@/store/storeconstants'
import LoginValidations from '@/services/LoginValidations'

export default {
  data() {
    return {
      email: '',
      password: '',
      errors: [],
      error: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions('auth', {
      login: LOGIN_ACTION
    }),

    async onLogin() {
      let validations = new LoginValidations(this.email, this.password)
      this.errors = validations.checkValidations()
      if (Object.keys(this.errors).length) {
        return false
      }

      try {
        this.error = ''
        this.isLoading = true

        await this.login({
          email: this.email,
          password: this.password
        })

        this.isLoading = false
        this.$router.push('/')
      } catch (error) {
        this.error = error
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  font-size: 0.8rem;
  color: red;
}

.btn-primary {
  background-color: #12c6be;
  border: #09948d;

  &:hover {
    background-color: #09948d;
  }
}

.form-check-input:checked {
  background-color: #12c6be;
  border-color: #12c6be;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #008f89;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 143, 137, 0.25);
}

.divider:after,
.divider:before {
  content: '';
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
</style>
