import { GET_MEMBERS, GET_MEMBER_ERROR } from '@/store/storeconstants'

export default {
  [GET_MEMBERS]: (state) => {
    return state.members
  },

  [GET_MEMBER_ERROR]: (state) => {
    return state.error
  }
}
