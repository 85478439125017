import { SET_DISC_PERSONALITY, SET_DISC_ERROR } from '@/store/storeconstants'
export default {
  [SET_DISC_PERSONALITY](state, payload) {
    state.personalities = payload.personalities
    state.scores = payload.scores
    state.isLoading = payload.isLoading
    state.error = payload.error
  },

  [SET_DISC_ERROR](state, errorMessage) {
    state.error = errorMessage
  }
}
