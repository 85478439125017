import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      personalities: null,
      scores: null,
      isLoading: false,
      error: null
    }
  },
  mutations,
  getters,
  actions
}
