/* eslint-disable */
export default class Validations {
  static checkFill(name) {
    if (name === '') {
      return false
    }
    return true
  }

  static checkPhone(phone) {
    var number = /^[0-9]+$/
    var phoneno = /\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/g
    if (number.test(phone) && phone.match(phoneno)) {
      return true
    }
    return false
  }

  static checkEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  static minLength(name, minLength) {
    if (name.length < minLength) {
      return false
    }
    return true
  }
}
