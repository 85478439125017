<template lang="">
  <section class="resume-section">
    <div class="resume-section-content">
      <template v-if="dataUser">
        <div v-if="dataUser.profile">
          <h1 class="mb-0">
            {{ dataUser.profile.first_name }}
            <span class="text-primary">{{ dataUser.profile.last_name }}</span>
          </h1>
          <div class="subheading mb-5">
            {{ dataUser.profile.address }}. {{ dataUser.profile.phone }}.
            <a href="mailto:name@email.com" class="text-lowercase">{{
              dataUser.email
            }}</a>
          </div>
          <button class="btn btn-primary" @click.prevent="onCreateProfile">
            Perbarui Informasi Tentang Anda
          </button>
        </div>
        <div v-else>
          <template v-if="dataUser.name">
            <h1 class="mb-0">
              {{ dataUser.name }}
            </h1>
            <div class="subheading mb-5">
              <a href="mailto:name@email.com" class="text-lowercase">{{
                dataUser.email
              }}</a>
            </div>
            <button class="btn btn-primary" @click.prevent="onCreateProfile">
              Perbarui Informasi Tentang Anda
            </button>
          </template>
          <template v-else>
            <button class="btn btn-primary" @click.prevent="onCreateProfile">
              Lengkapi Tentang Anda
            </button>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      dataUser: null,
      pathname: 'me'
    }
  },
  mounted() {
    let { name } = this.$route
    if (name.includes('me')) this.pathname = 'me'
    else this.pathname = 'member'

    this.fetchDataUser()
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user
    }),

    ...mapState('member', {
      members: (state) => state.members
    })
  },
  methods: {
    fetchDataUser() {
      const { params } = this.$route
      const id = params.id
      let member = []
      if (this.members) {
        member = this.members.find((item) => parseInt(item.id) === parseInt(id))
      }

      if (this.pathname !== 'me') this.dataUser = member
      else this.dataUser = this.user
    },
    onCreateProfile() {
      this.$router.push('/me/profile')
    }
  }
}
</script>
