import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import ProfileLayout from '../layouts/ProfileLayout.vue'
import HomeView from '../views/HomeView.vue'
import ProfileAboutView from '../views/profiles/ProfileAboutView.vue'
import ProfileCreateProfile from '../views/profiles/ProfileCreateProfile.vue'
import ProfilePapikostickView from '../views/profiles/ProfilePapikostickView.vue'
import ProfilePapikostickInstuctionView from '../views/profiles/ProfilePapikostickInstuctionView.vue'
import ProfilePapikostickTestView from '../views/profiles/ProfilePapikostickTestView.vue'
import ProfileDiscView from '../views/profiles/ProfileDiscView.vue'
import ProfileDiscPersonality from '../views/profiles/ProfileDiscPersonality.vue'
import LoginView from '../views/LoginView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import store from '@/store'
import { IS_USER_AUTHENTICATE_GETTER } from '@/store/storeconstants'

const routes = [
  {
    path: '/',
    component: MainLayout,
    meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'test',
        component: HomeView
      }
    ]
  },
  {
    path: '/me',
    component: ProfileLayout,
    meta: { auth: true },
    children: [
      {
        path: '/me',
        name: 'me',
        component: ProfileAboutView
      },
      {
        path: '/me/profile',
        name: 'me profile',
        component: ProfileCreateProfile
      },
      {
        path: '/me/papikostick',
        name: 'me papikostick',
        component: ProfilePapikostickView
      },
      {
        path: '/me/papikostick/instruction',
        name: 'me papikostick instruction',
        component: ProfilePapikostickInstuctionView
      },
      {
        path: '/me/papikostick/test',
        name: 'me papikostick test',
        component: ProfilePapikostickTestView
      },
      {
        path: '/me/disc',
        name: 'me disc',
        component: ProfileDiscView
      },
      {
        path: '/me/disc/personalities',
        name: 'me disc personalities',
        component: ProfileDiscPersonality
      }
    ]
  },
  {
    path: '/member',
    component: ProfileLayout,
    meta: { auth: true },
    children: [
      {
        path: '/member',
        name: 'notFound',
        component: NotFoundView
      },
      {
        path: '/member/:id',
        name: 'profile',
        component: ProfileAboutView
      },
      {
        path: '/member/:id/papikostick',
        name: 'papikostick',
        component: ProfilePapikostickView
      },
      {
        path: '/member/:id/papikostick/instruction',
        name: 'papikostick instruction',
        component: ProfilePapikostickInstuctionView
      },
      {
        path: '/member/:id/papikostick/test',
        name: 'papikostick test',
        component: ProfilePapikostickTestView
      },
      {
        path: '/member/:id/disc',
        name: 'disc',
        component: ProfileDiscView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { auth: false }
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundView,
    meta: { auth: true }
  },
  {
    path: '/:pathOne/:pathTwo(.*)',
    component: NotFoundView,
    meta: { auth: true }
  },
  {
    path: '/:pathOne/:pathTwo/:pathThree(.*)',
    component: NotFoundView,
    meta: { auth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]) {
    next('/login')
  } else if (
    !to.meta.auth &&
    store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]
  ) {
    next('/')
  } else {
    next()
  }
})

export default router
