<template>
  <div>
    <canvas
      ref="lineChart"
      :style="{ width: chartWidth + 'px', height: chartHeight + 'px' }"
    ></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'LineChart',
  props: {
    dataCharts: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    bgColor: {
      type: String
    }
  },
  data() {
    return {
      chartWidth: 100,
      chartHeight: 400
    }
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const type = this.type
      const data = this.dataCharts
      const bgColor = this.bgColor ? this.bgColor : 'rgb(252, 186, 3)'
      const ctx = this.$refs.lineChart.getContext('2d')
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['D', 'I', 'S', 'C'],
          datasets: [
            {
              label: type,
              data: [data.d, data.i, data.s, data.c],
              borderColor: bgColor,
              borderWidth: 3,
              tension: 0.2
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 50,
              right: 50,
              top: 0,
              bottom: 0
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                padding: 25,
                usePointStyle: true,
                lineHeight: 5.5,
                maxWidth: 500
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              stacked: true,
              grid: {
                display: true
              }
            }
          }
        }
      })
    }
  }
}
</script>
