<template>
  <section class="resume-section">
    <div class="resume-section-content">
      <h2 class="mb-5">PAPI Kostick Test</h2>
      <div
        class="alert alert-warning alert-dismissible fade show"
        role="alert"
        v-if="error"
      >
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          @click.prevent="closeAlert"
        ></button>
        <h4 class="alert-heading">Gagal!</h4>
        <p>{{ error }}</p>
      </div>

      <table v-if="isLoading" class="table">
        <tr>
          <td colspan="3" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </table>

      <div v-else>
        <table class="table table-primary">
          <tbody>
            <tr>
              <th width="5%" class="text-center">No</th>
              <th width="15%" class="text-center">Pilihan</th>
              <th>Pernyataan</th>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-condensed"
          width="100%"
          v-for="post in postRender"
          :key="post.id"
        >
          <tbody>
            <tr>
              <td rowspan="2" width="5%" class="text-center align-middle">
                {{ post.id }}
              </td>
              <td width="15%" class="text-center border-bottom-0 py-2 px-0">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :id="`s_${post.id}_0`"
                    :name="`s[${post.id}]`"
                    :value="`${post.value1}`"
                    v-model="postAnswers[post.id - 1]"
                    @change.prevent="onSelection()"
                  />
                </div>
              </td>
              <td class="border-bottom-0 align-middle">
                <label class="form-check-label" :for="`s_${post.id}_0`">
                  {{ post.statement1 }}
                </label>
              </td>
            </tr>
            <tr>
              <td width="15%" class="text-center py-2 px-0">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :id="`s_${post.id}_1`"
                    :name="`s[${post.id}]`"
                    :value="`${post.value2}`"
                    v-model="postAnswers[post.id - 1]"
                    @change.prevent="onSelection()"
                  />
                </div>
              </td>
              <td class="align-middle">
                <label class="form-check-label" :for="`s_${post.id}_1`">
                  {{ post.statement2 }}
                </label>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-3">
          <div class="col">
            <div class="btn-group">
              <button
                class="btn btn-primary"
                @click.prevent="loadprev()"
                :disabled="prevdisabled"
              >
                Prev
              </button>
              <button
                class="btn btn-primary"
                @click.prevent="loadnext()"
                :disabled="nextdisabled"
              >
                Next
              </button>
            </div>
          </div>
          <div class="col">
            <button
              class="btn btn-success float-end"
              @click.prevent="onSubmit()"
              :disabled="isLoading"
            >
              Submit &nbsp;
              <span
                v-if="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  PAPIKOSTICK_QUESTION_ACTION,
  SUBMIT_PAPIKOSTICK_ACTION,
  SET_PAPIKOSTICK_ERROR
} from '@/store/storeconstants'

export default {
  name: 'PapikostickTestView',
  data() {
    return {
      start: 0,
      end: 5,
      posts: [],
      postRender: [],
      postAnswers: [],
      prevdisabled: false,
      nextdisabled: false
    }
  },
  mounted() {
    this.onGetQuestion()
  },
  computed: {
    ...mapState('papikostick', {
      questions: (state) => state.questions,
      error: (state) => state.error,
      isLoading: (state) => state.isLoading
    })
  },
  methods: {
    ...mapActions('papikostick', {
      getQuestions: PAPIKOSTICK_QUESTION_ACTION,
      submit: SUBMIT_PAPIKOSTICK_ACTION
    }),

    async onGetQuestion() {
      await this.getQuestions()
      this.formatDataPosts(this.questions)
      if (this.start == 0) {
        this.prevdisabled = true
      }
    },

    async onSubmit() {
      let datanull = []
      this.postAnswers.map((item, index) => {
        if (item == null) {
          datanull.push(index + 1)
        }
      })

      if (datanull.length > 0) {
        let errorMessage = `Pertanyaan No: ${datanull.join(', ')} Belum diisi!`
        this.$store.commit(`papikostick/${SET_PAPIKOSTICK_ERROR}`, errorMessage)
        return false
      }

      await this.submit(this.postAnswers)
    },

    formatDataPosts(posts) {
      for (let key in posts) {
        this.posts.push({ ...posts[key], id: parseInt(key) + 1 })
        this.postAnswers.push(null)
      }

      for (let i = this.start; i < this.end; i++) {
        this.postRender.push({ ...this.posts[i] })
      }
    },

    loadnext() {
      this.start += this.postRender.length
      this.end += this.postRender.length

      this.postRender = []
      for (let i = this.start; i < this.end; i++) {
        this.postRender.push({ ...this.posts[i] })
      }

      if (this.end == 90) {
        this.nextdisabled = true
      }

      this.prevdisabled = false
    },

    loadprev() {
      this.start -= this.postRender.length
      this.end -= this.postRender.length

      this.postRender = []
      for (let i = this.start; i < this.end; i++) {
        this.postRender.push({ ...this.posts[i] })
      }

      if (this.start == 0) {
        this.prevdisabled = true
      }

      this.nextdisabled = false
    },

    onSelection() {
      this.$store.commit(`papikostick/${SET_PAPIKOSTICK_ERROR}`, null)
    },

    closeAlert() {
      this.$store.commit(`papikostick/${SET_PAPIKOSTICK_ERROR}`, null)
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 0;
  td {
    padding: 10px 0;
  }
}

input[type='radio'] {
  font-size: 26px;
}

.form-check .form-check-input {
  float: none;
}
</style>
